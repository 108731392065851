<template>
    <div class="activityDistribution">
        <div class="page-title flex">
            <div><span class="ant-btn-primary"></span>分销</div>
            <div>
                <div>
                    <!--<a-button type="primary" @click="_save">保存并发布</a-button>-->
                    <a-button style="background: #0ECB74;margin-left: 15px;color: #fff;border-color: #0ECB74" @click="_save">保存</a-button>
                    <a-button style="margin-left: 15px;" @click="_alertClose">返回</a-button>
                </div>
            </div>
        </div>

        <div class="container">
            <a-row type="flex" class="div-row">
                <a-col class="row-label">是否参与分销</a-col>
                <a-col style="width: 600px;">
                    <a-checkbox style="margin-right: 20px;" :checked="participation" @change="_check">参与</a-checkbox>
                    <a-checkbox :checked="!participation" @change="_check">不参与</a-checkbox>
                    <div class="hint">开启分销后默认开启参与分销。关闭则不参与分销，不产生分销佣金。</div>
                </a-col>
            </a-row>

            <a-row type="flex" class="div-row">
                <a-col class="row-label">名单佣金</a-col>
                <a-col style="width: 600px;">
                    <a-switch checked-children="开" un-checked-children="关" v-model="IsNumCommission"/>
                    <div class="hint">不开启则使用默认佣金设置规则；开启则使用名单佣金比例规则</div>
                    <div style="margin: 15px 0;">
                        <a-input  style=";max-width:200px;" type="number" :min="0" addon-before="有效名单" addon-after="元/个" v-model="ValidListNum"/>
                    </div>
                    <div style="margin: 15px 0;">
                        <a-input  style=";max-width:200px;" type="number" :min="0" addon-before="体验名单" addon-after="元/个" v-model="ExperienceListNum"/>
                    </div>
                    <div style="margin: 15px 0;">
                        <a-input  style=";max-width:200px;" type="number" :min="0" addon-before="成交名单" addon-after="元/个" v-model="SignListNum"/>
                    </div>
                </a-col>
            </a-row>

            <a-row type="flex" class="div-row">
                <a-col class="row-label">订单佣金</a-col>
                <a-col style="width: 600px;">
                    <a-switch checked-children="开" un-checked-children="关" v-model="IsOrderCommission"/>
                    <div style="margin: 15px 0;">
                        <a-radio :checked="orderRadio" @change="_orderRadio">比例返佣</a-radio>
                        <a-radio :checked="!orderRadio" @change="_orderRadio">固定返佣</a-radio>
                    </div>
                    <div style="margin: 15px 0;" v-if="orderRadio">
                        <a-input  style="max-width:200px;margin-right: 20px;" type="number" :min="0" addon-before="一级返佣" addon-after="%" v-model="OrderCommissionFirst"/>
                        <a-input  style=";max-width:200px;" type="number" :min="0" addon-before="二级返佣" addon-after="%" v-model="OrderCommissionSecond"/>
                    </div>
                    <div style="margin: 15px 0;" v-if="!orderRadio">
                        <a-input  style="max-width:200px;margin-right: 20px;" type="number" :min="0" addon-before="一级返佣" addon-after="元" v-model="OrderCommissionFirst"/>
                        <a-input  style="max-width:200px;" type="number" :min="0" addon-before="二级返佣" addon-after="元" v-model="OrderCommissionSecond"/>
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
    export default {
        name: "activityDistribution",
        props: {
            getData: {
                type: Object
            },
        },
        data(){
            return {
                participation: true,
                commission: true,
                order: false,
                orderRadio: true,
                Pid:'',
                IsParticipatioDistribution:0,
                IsNumCommission:false,
                ValidListNum:0,
                ExperienceListNum:0,
                SignListNum:0,
                IsOrderCommission:false,
                OrderCommissionReturnType:0,
                OrderCommissionFirst:0,
                OrderCommissionSecond:0,

            }
        },
        mounted(){
            let self = this;
            if(this.getData){
                self.participation = this.getData.IsParticipatioDistribution==1?true:false;
                self.IsNumCommission = this.getData.IsNumCommission==1? true : false;
                self.ValidListNum = this.getData.ValidListNum;
                self.ExperienceListNum = this.getData.ExperienceListNum;
                self.SignListNum = this.getData.SignListNum;
                self.IsOrderCommission = this.getData.IsOrderCommission == 1? true: false;
                self.OrderCommissionReturnType = this.getData.OrderCommissionReturnType == 1? true: false;
                self.OrderCommissionFirst = this.getData.OrderCommissionFirst;
                self.OrderCommissionSecond = this.getData.OrderCommissionSecond;
                self.orderRadio = this.getData.OrderCommissionReturnType == 1? true: false;
            }
        },
        methods: {
            _alertClose(){
                this.$emit('close',false);
            },
            _orderRadio(){
                this.orderRadio = !this.orderRadio;
            },
            _check(){
                this.participation = !this.participation;
                this.IsParticipatioDistribution = this.participation?1:0;
            },
            _save(){
                let self = this;
                this.$message.loading('数据提交中...',0);
                this.$axios.post(8000035,{
                    Pid: self.getData?self.getData.skipId:'',
                    IsParticipatioDistribution: self.participation?1:0,
                    IsNumCommission: self.IsNumCommission?1:0,
                    ValidListNum: self.ValidListNum,
                    ExperienceListNum: self.ExperienceListNum,
                    SignListNum: self.SignListNum,
                    IsOrderCommission: self.IsOrderCommission?1:0,
                    OrderCommissionReturnType: self.orderRadio?1:2,
                    OrderCommissionFirst: self.OrderCommissionFirst,
                    OrderCommissionSecond: self.OrderCommissionSecond,
                },res=>{
                    if (res.data.code == 1) {
                        this.$message.success(res.data.msg);
                        this.$emit('setItem','')
                    } else {
                        this.$message.error(res.data.msg);
                    }
                    this.$message.destroy();
                })
            },
        }
    }
</script>

<style scoped lang="less">
    .activityDistribution{
        padding: 20px;
        background: #fff;
        .page-title{
            span{
                display: inline-block;
                vertical-align: sub;
                margin-right: 8px;
                width: 3px;
                height: 21px;
            }
            color: #242424;
            font-size: 16px;
        }
        .container{
            padding-left: 90px;
            .div-row{
                margin-top: 20px;
                .row-label{
                    width: 110px;
                }
                .hint{
                    margin: 5px 0;
                    color: #ABB0BF;
                    font-size: 13px;
                }
            }
        }
    }
</style>
