<template>
    <div class="distributionShare">
        <!--海报设置-->
        <div class="poster">
            <div class="share-seting flex">
                <div class="seting-title">海报设置</div>
                <div>
                    <a-button style="margin-right: 20px" type="primary" @click="onObjectSelected">保存</a-button>
                    <a-button style="margin:0px 20px" type="primary" @click="_preview">预览海报</a-button>
                    <a-button type="primary" @click="_link">预览链接</a-button>
                </div>

            </div>
            <p class="poster-title">开启后该商品将按下方设置的内容生成海报</p>
            <div class="poster-content flex" >
                <div class="poster-image" :style="'max-width:'+pageWidth+'px;width:'+BigWidth+'px'">
                    <canvas  :width="BigWidth" :height="BigHeight" id="canvas"></canvas>

                    <img crossOrigin='anonymous' id="images" style="display: none;opacity: 0" :src="fileUrl" alt="">
                </div>
                <div class="poster-seting">
                    <!--<div class="group">-->
                        <!--<div class="label">画布宽度</div>-->
                        <!--<a-radio-group class="item" @change="_Resolving" :default-value="ResolvingPower"  >-->
                            <!--<a-radio :value="1">-->
                                <!--640*1008-->
                            <!--</a-radio>-->
                            <!--<a-radio :value="2">-->
                                <!--1080*1920-->
                            <!--</a-radio>-->
                        <!--</a-radio-group>-->
                    <!--</div>-->
                    <div class="group">
                        <div class="label">海报宽度</div>
                        <a-input-number  :min="100" v-model="BigWidth"  @change="ChangeWidth" />

                    </div>
                    <div class="group">
                        <div class="label">海报高度</div>
                        <a-input-number  :min="100" v-model="BigHeight"  @change="ChangeHeight" />
                    </div>
                    <div class="group">
                        <div class="label">背景图片</div>
                        <a-button type="primary" class="upload item">
                            选择图片
                            <input id="upload" accept=".jpg,.png,.gif,.jpeg" type="file"
                                   @change="_upload($event,'upload')">
                        </a-button>
                    </div>
                    <div class="group">
                        <div class="label">海报元素</div>
                        <div class="item element">
                            <a-button v-for="(item,index) in element" @click="_element(index)" class="element-bth">
                                {{item.title}}
                            </a-button>
                        </div>

                    </div>
                    <div class="group">
                        <div class="label">文字大小</div>
                        <a-select class="item" @change="textChange">
                            <a-select-option v-for="item in Pxnum" :value="item">{{item}}</a-select-option>
                        </a-select>
                    </div>
                    <div class="group">
                        <div class="label">文字颜色</div>
                        <div class="item color">
                            <div class="color_con" :style="{background:color}"></div>
                            <div style="overflow: hidden;position: relative">
                                <sketch-picker v-model="colors" @input="updateValue"></sketch-picker>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div v-viewer style="display: none">
            <img id="preview" :src="PreviewImgUrl" alt="">
        </div>
    </div>
</template>

<script>
    import 'viewerjs/dist/viewer.css'
    import Viewer from 'v-viewer'
    import {fabric} from "fabric";
    import 'fabric-customise-controls';
    import Vue from 'vue'

    Vue.use(Viewer);
    import {Sketch} from 'vue-color'

    export default {
        components: {
            'sketch-picker': Sketch
        },
        props: {
            getData: {
                type: Object
            },
        },
        name: "distributionShare",
        data() {
            return {
                colors: {
                    hex: '#1890ff',
                    hsl: {h: 150, s: 0.5, l: 0.2, a: 1},
                    hsv: {h: 150, s: 0.66, v: 0.30, a: 1},
                    rgba: {r: 25, g: 77, b: 51, a: 1},
                    a: 1
                },
                fabricCanvas: '',
                BigHeight: 1920,
                BigWidth: 1080,
                colorsStatus: false,
                color: '#000000',
                fontSize:24,
                Pxnum: [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68, 70, 72, 74, 76, 78, 80, 82, 84, 86, 88, 90, 92, 94, 96, 98, 100],
                fileUrl1: 'https://sk-fenxiao.qitianzhen.cn/Fvh7K1SLXAcWGXdHzcMvq5Nyvn5-.png',
                fileUrl:'',
                // https://smart-resource.sikegroup.com/6d85855ec60be064145d93eb6a236c8e.jpg
                deleteIcon: 'http://sk-ims-cabinet.sikegroup.com/Fn88byH8eOgXqiSmudFXFpBzcKpi',
                canvas: '',
                element: [
                    {"title": "头像", "field": "avatar", "value": "https://sk-fenxiao.qitianzhen.cn/avatar.png", "type": 'image'},
                    {"title": "昵称", "field": "nickname", "value": "昵称", "type": 'text'},
                    {"title": "文字", "field": "text", "value": "文字", "type": 'text'},
                    {
                        "title": "二维码",
                        "field": "code",
                        "value": "https://sk-fenxiao.qitianzhen.cn/showqrcode%20%281%29.png",
                        "type": 'image'
                    },
                    {"title": "商品名称", "field": "productName", "value": "商品名称", "type": 'text'},
                    {"title": "商品现价", "field": "nowPrice", "value": "商品现价", "type": 'text'},
                    {"title": "商品原价", "field": "productPrice", "value": "商品原价", "type": 'text'},

                ],
                imgOption: [],
                textOption: [],
                statusimg:false,
                ResolvingPower:2,
                Pid: '',
                PreviewImgUrl:'',
                changeFabricField:'',
                changeFabricType:'',
                pageWidth:'',

            }
        },

        mounted() {
            this._draw();
            let self = this;
            let infoData = this.getData;
            this.Pid = this.getData.skipId;
            if(infoData.BackgroundImgUrl){
                self.fileUrl = infoData.BackgroundImgUrl;
                
            }
            if(infoData.DataImgInfo){
                self.imgOption = infoData.DataImgInfo;
            }
            if(infoData.DataTextInfo){
                self.textOption = infoData.DataTextInfo;
            }

            self.BigHeight = infoData.BigHeight;
            self.BigWidth  = infoData.BigWidth;
            self.fabricCanvas.setHeight(self.BigHeight);
            self.fabricCanvas.setWidth(self.BigWidth);
            this.iniCanvas();
            // this.loadData();
        },
        created() {
            this.pageWidth =  document.documentElement.clientWidth-350-440

        },
        methods: {
            _link(){
                let url = "https://q.qitianzhen.cn/WxActivity/dis_poster?Pid="+this.Pid;
                window.open(url);
            },
            _preview(){
                this.PreviewImgUrl = this.fabricCanvas.toDataURL('png');
                var e = document.createEvent("MouseEvents"); //这里的clickME可以换成你想触发行为的DOM结点
                e.initEvent("click", true, true); //这里的click可以换成你想触发的行为
                document.getElementById("preview").dispatchEvent(e);

            },
            loadData(){
                let self = this;
                this.$axios.get(1234,{
                    Id:self.Pid,
                },res=>{
                    if(res.data.code==0){
                        let infoData = res.data.data.poster;
                        if(infoData.BackgroundImgUrl){
                            self.fileUrl = infoData.BackgroundImgUrl;
                        }
                        if(infoData.DataImgInfo){
                            self.imgOption = infoData.DataImgInfo;
                        }
                        if(infoData.DataTextInfo){
                            self.textOption = infoData.DataTextInfo;
                        }

                        self.BigHeight = infoData.BigHeight;
                        self.BigWidth  = infoData.BigWidth;
                        self.fabricCanvas.setHeight(self.BigHeight);
                        self.fabricCanvas.setWidth(self.BigWidth);
                        // self.fabricCanvas.clear();
                        // self.fabricCanvas.renderAll();

                        self.iniCanvas();
                    }else{

                        this.$message.error(res.data.msg);
                    }
                })
            },
            ChangeHeight(){
                this.fabricCanvas.setHeight(this.BigHeight);
                this.fabricCanvas.clear();
                // this.fabricCanvas.renderAll();
                this.iniCanvas();
            },
            ChangeWidth(){
                this.fabricCanvas.setWidth(this.BigWidth);
                // this.fabricCanvas.setScale(0.1);
                this.fabricCanvas.clear();
                // this.fabricCanvas.renderAll();
                this.iniCanvas();
            },
            _Resolving(e){
                if(e.target.value==1){
                    this.BigHeight = 1008;
                    this.BigWidth  = 640;
                }else{
                    this.BigHeight = 1920;
                    this.BigWidth=1080;
                }
                let self = this;

                if(this.ResolvingPower != e.target.value){
                    this.fabricCanvas.setHeight(this.BigHeight);
                    this.fabricCanvas.setWidth(this.BigWidth);
                    this.fabricCanvas.clear();
                    this.fabricCanvas.renderAll();
                    this.iniCanvas();
                }
                this.ResolvingPower = e.target.value;
            },
            updateValue(val) {
                this.color = val.hex;
                if(this.changeFabricType =='text'){
                    if(this.textOption.length>0){
                        this.iniCanvas();
                    }else{
                        this.$message.error('请先添加文字类型海报元素');
                    }
                }else{
                    this.$message.error('文字类型才可以改变颜色');

                }
            },
            textChange(value){
                this.fontSize = value;
                if(this.changeFabricType =='text'){
                    if(this.textOption.length>0){
                        this.iniCanvas();
                    }else{
                        this.$message.error('请先添加文字类型海报元素');
                    }
                }else{
                    this.$message.error('文字类型才可以改变颜色');

                }

            },
            _element(index) {
                if(!this.fileUrl){
                    this.$message.error('请先上传背景图');
                    return false;
                }
                if (this.element[index].type == 'text') {
                    // if(this.textOption.length>0){
                    //    let status = 0;
                    //    for (var i=0;i<this.textOption.length;i++){
                    //        if(this.textOption[i].field == this.element[index].field){
                    //            status = 1;
                    //
                    //            break;
                    //        }
                    //    }
                    //    if(status==1){
                    //        this.$message.error('每一个海报元素只能选择一个类型');
                    //        return false;
                    //    }
                    // }
                    let arr = {
                        "value": this.element[index].value,
                        "left": 50,
                        "top": 100,
                        "fill": "#000000",
                        "fontSize":"24",
                        "field":this.element[index].field,
                        "fieldType":this.element[index].type,
                        "cacheKey":this.element[index].field,
                        "scaleX":1,
                        "scaleY":1,
                        "angle":0,
                    };
                    this.textOption.push(arr);
                    this.iniCanvas();
                }else{
                    // if(this.imgOption.length>0){
                    //     let status = 0;
                    //     for (var i=0;i<this.imgOption.length;i++){
                    //         if(this.imgOption[i].field == this.element[index].field){
                    //             status = 1;
                    //             break;
                    //         }
                    //     }
                    //     if(status==1){
                    //         this.$message.error('每一个海报元素只能选择一个类型');
                    //         return false;
                    //     }
                    // }
                    let arr ={
                        "left":100,
                        "top":100,
                        "width":600,
                        "height":600,
                        "angle":0,//设置图形顺时针旋转角度
                        "field":this.element[index].field,
                        "fieldType":this.element[index].type,
                         "value":this.element[index].value,
                        "cacheKey":this.element[index].field,
                        "scaleX":0.3,
                        "scaleY":0.3,

                    };
                    this.imgOption.push(arr);
                    this.iniCanvas();
                }

            },
            iniCanvas(){
                let self = this;
                let  canvas =  self.fabricCanvas;
                canvas.clear();
                //背景图
                if(self.fileUrl){
                    let imgs = new Image();
                    imgs.src = self.fileUrl;
                    imgs.crossOrigin='anonymous';
                    let imgInstance = new fabric.Image(imgs, {
                        //设置图片位置和样子
                        left: 0,
                        top: 0,
                        width: self.BigWidth,
                        height: self.BigHeight,
                        angle: 0,
                        selectable: false, //禁止选中
                        fieldType:'bg',
                        field:'bg',
                        // scaleX:imgs.width/self.BigWidth,  //等比缩放
                        // scaleY:imgs.height/self.BigHeight,
                    });
                    canvas.add(imgInstance);//加入到canvas中
                }

                let imgfield = [];
                //图片
                if(self.imgOption.length>0){
                    for (let s=0;s<self.imgOption.length>0;s++){
                        imgfield.push(self.imgOption[s].field);
                        if(imgfield.indexOf(self.imgOption[s].field) != -1){
                            let img = new Image();
                            img.src = self.imgOption[s].value;
                            img.crossOrigin='anonymous';
                            img.onload = function () {
                                let imgInstance2 = new fabric.Image(img,self.imgOption[s]);
                                if(self.imgOption[s].field == 'avatar'){
                                    imgInstance2.clipTo = function(ctx) {
                                        // console.log(self.imgOption[s]);
                                        ctx.arc(0, 0, self.imgOption[s].width/2, 0, Math.PI * 2, true);//裁剪圆形
                                    };
                                }
                                canvas.add(imgInstance2);//加入到canvas中
                            }
                        }
                    }
                }
                //文字
                let textfield = [];
                if(self.textOption.length>0){
                    for (let i=0;i<self.textOption.length;i++){
                        textfield.push(self.textOption[i].field);
                        if(textfield.indexOf(self.textOption[i].field) !=-1){
                            if(self.textOption[i].field == self.changeFabricField){
                                self.textOption[i].fill = self.color;
                                self.textOption[i].fontSize = self.fontSize;
                            }
                            let text = new fabric.IText(self.textOption[i].value, self.textOption[i]);
                            canvas.add(text);
                            canvas.setActiveObject(text);
                        }
                    }
                }
                canvas.renderAll();

            },
            _draw() {
                var deleteImg = document.createElement('img');
                deleteImg.src = this.deleteIcon;
                let self = this;

                fabric.Object.prototype.lockUniScaling = true; //只显示四角的操作
                

                document.getElementById('canvas').style.width = self.BigWidth;
                document.getElementById('canvas').style.height = self.BigHeight;


                let canvas = new fabric.Canvas('canvas');

                this.fabricCanvas = canvas;
                canvas.on("object:modified", function (options) {
                    self.changeFabricField = options.target.field;
                    self.changeFabricType = options.target.fieldType;
                    // self.canvasChange();
                    if(options.target.fieldType =='text'){
                        if(self.textOption.length>0){
                            for (let i=0;i<self.textOption.length;i++){
                                if(self.textOption[i].field == options.target.field){
                                    self.textOption[i].value = options.target.value;
                                    self.textOption[i].left = options.target.left;
                                    self.textOption[i].top = options.target.top;
                                    self.textOption[i].fill = options.target.fill;
                                    self.textOption[i].fontSize = options.target.fontSize;
                                    self.textOption[i].fieldType = options.target.fieldType;
                                    self.textOption[i].scaleX = options.target.scaleX;
                                    self.textOption[i].scaleY = options.target.scaleY;
                                    self.textOption[i].angle = options.target.angle;
                                }
                            }
                        }
                    }else{
                        if(self.imgOption.length>0){
                            for (let i=0;i<self.imgOption.length;i++){
                                if(self.imgOption[i].field == options.target.field) {
                                    self.imgOption[i].left = options.target.left;
                                    self.imgOption[i].value = options.target.value;
                                    self.imgOption[i].top = options.target.top;
                                    self.imgOption[i].width = options.target.width;
                                    self.imgOption[i].height = options.target.height;
                                    self.imgOption[i].angle = options.target.angle;
                                    self.imgOption[i].field = options.target.field;
                                    self.imgOption[i].fieldType = options.target.fieldType;
                                    self.imgOption[i].scaleX = options.target.scaleX;
                                    self.imgOption[i].scaleY = options.target.scaleY;
                                    self.imgOption[i].angle = options.target.angle;
                                }
                            }
                        }
                    }
                    // console.log(self.textOption);
                    // console.log(self.imgOption);


                });

                canvas.on("mouse:down", function (options){
                    self.changeFabricField = options.target.field;
                    self.changeFabricType = options.target.fieldType;
                    if(options.target.fieldType =='text'){
                        if(self.textOption.length>0){
                            for (let i=0;i<self.textOption.length;i++){
                                if(self.textOption[i].field == options.target.field){
                                    self.textOption[i].value = options.target.value;
                                    self.textOption[i].left = options.target.left;
                                    self.textOption[i].top = options.target.top;
                                    self.textOption[i].fill = options.target.fill;
                                    self.textOption[i].fontSize = options.target.fontSize;
                                    self.textOption[i].fieldType = options.target.fieldType;
                                    self.textOption[i].scaleX = options.target.scaleX;
                                    self.textOption[i].scaleY = options.target.scaleY;
                                    self.textOption[i].angle = options.target.angle;
                                }
                            }
                        }
                    }
                    self.canvasChange();
                }) ;

                // 左上角控制按钮修改为删除
                fabric.Canvas.prototype.customiseControls({
                    settings: {
                        borderColor: 'blue',
                        cornerSize: 8,
                        cornerShape: 'circle',//rect,circle
                        cornerStyle: 'circle',
                        cornerBackgroundColor: 'red',
                        cornerPadding: 0 ,
                        
                    },
                    tl: {
                        action: 'remove',
                        cursor: this.deleteIcon

                    },
                    

                }, function () {
                    canvas.renderAll();

                });
                //左上角控制按钮图标修改
                fabric.Object.prototype.customiseCornerIcons({
                    settings: {
                        borderColor: 'blue',
                        cornerSize: 10,
                        cornerShape: 'circle',//rect,circle
                        cornerStyle: 'circle',
                        cornerBackgroundColor: 'red',
                        cornerPadding: 0 ,
                        lockUniScaling: false, //只显示四角的操作
                    },
                    tl: {

                        icon: this.deleteIcon ,
                        cursor: 'pointer',
                        settings: {
                            borderColor: '#FFFFFF',
                            cornerSize: 8,
                            cornerShape: 'circle',//rect,circle
                            cornerStyle: 'circle',
                            cornerBackgroundColor: '#FFFFFF',
                            cornerPadding: 0 ,

                        },
                    },
                    tr: {
                        cursor: 'pointer',
                        settings: {

                            cornerBackgroundColor: '#FFFFFF',

                        },
                    },
                    bl: {
                        settings: {

                            cornerBackgroundColor: '#FFFFFF',

                        },
                    },
                    br: {
                        settings: {

                            cornerBackgroundColor: '#FFFFFF',

                        },
                    },
                    mb: {
                        settings: {

                            cornerBackgroundColor: '#FFFFFF',

                        },
                    },
                    mt: {
                        settings: {

                            cornerBackgroundColor: '#FFFFFF',

                        },
                    },
                    ml: {
                        settings: {

                            cornerBackgroundColor: '#FFFFFF',

                        },
                    },
                    mr: {
                        settings: {

                            cornerBackgroundColor: '#FFFFFF',

                        },
                    },



                }, function () {
                    canvas.renderAll();
                });
                this.canvas = canvas._objects;

            },
            canvasChange(){
                let self = this;
                console.log(self.canvas);
                if(self.canvas){
                    self.textOption = [];
                    self.imgOption = [];
                    let textfield = [];
                    let imgfield = [];
                    for (var i=0 ;i<self.canvas.length;i++){
                        if(self.canvas[i].hasOwnProperty('fieldType')){
                            if(self.canvas[i].fieldType=='text'){
                                let arr = {
                                    "value": self.canvas[i].value,
                                    "left": self.canvas[i].left,
                                    "top": self.canvas[i].top,
                                    "fill": self.canvas[i].fill,
                                    "fontSize":self.canvas[i].fontSize,
                                    "field":self.canvas[i].field,
                                    "fieldType":self.canvas[i].fieldType,
                                    "cacheKey":self.canvas[i].cacheKey,
                                    "scaleX":self.canvas[i].scaleX,
                                    "scaleY":self.canvas[i].scaleY,
                                    "angle":self.canvas[i].angle,
                                };
                                textfield.push(self.canvas[i].field);
                                if(textfield.indexOf(self.canvas[i].field) !=-1){
                                    self.textOption.push(arr);
                                }
                            }else if(self.canvas[i].fieldType=='image'){
                                let arr ={
                                    "left":self.canvas[i].left,
                                    "top":self.canvas[i].top,
                                    "width":self.canvas[i].width,
                                    "height":self.canvas[i].height,
                                    "angle":self.canvas[i].angle,//设置图形顺时针旋转角度
                                    "field":self.canvas[i].field,
                                    "fieldType":self.canvas[i].fieldType,
                                    "value":self.canvas[i].value,
                                    "cacheKey":self.canvas[i].cacheKey,
                                    "scaleX":self.canvas[i].scaleX,
                                    "scaleY":self.canvas[i].scaleY,

                                };
                                imgfield.push(self.canvas[i].field);
                                console.log(imgfield.indexOf(self.canvas[i].field));
                                if(imgfield.indexOf(self.canvas[i].field) !=-1){
                                    self.imgOption.push(arr);
                                }
                            }
                        }

                    }
                    // this.iniCanvas();
                }
            },
            onObjectSelected() {
                let self = this;
                let Resolving = '640*1008';
                if(this.ResolvingPower ==1){
                    Resolving = '640*1008';
                }else{
                    Resolving = '1080*1920';
                }

                this.$axios.post(1233,{
                    Pid:self.Pid,
                    ResolvingPower:Resolving,
                    BackgroundImgUrl:self.fileUrl,
                    DataTextInfo:self.textOption.length>0?JSON.stringify(self.textOption):'',
                    DataImgInfo:self.imgOption.length>0?JSON.stringify(self.imgOption):'',
                    PreviewImgUrl:self.PreviewImgUrl,
                    BigHeight:self.BigHeight,
                    BigWidth:self.BigWidth,

                },res=>{

                    if(res.data.code==0){
                        this.$message.success(res.data.msg);
                    }else{
                        this.$message.error(res.data.msg);
                    }

                })

            },
            _upload(e, name) {
                let self = this;
                let files = document.getElementById(name).files;
                let type = files[0].type;
                let img = new Image();
                let width = '';
                let height = '';

                if (type.indexOf("image/") != 0) {
                    this.$message.error('只允许上传jpg、gif、png、jpeg格式的图片');
                    return false;
                }
                this.__uploadFile__(files).then(res => {
                    if (res) {
                        self.fileUrl = res[0].FileUrl;
                        img.src = res[0].FileUrl;
                        img.onload = function () {
                            width = img.width;
                            height = img.height;
                            self.BigHeight = img.height;
                            self.BigWidth  = img.width;
                            self.fabricCanvas.setHeight(self.BigHeight);
                            self.fabricCanvas.setWidth(self.BigWidth);
                            
                            self.iniCanvas();
                        }


                    }
                })
            },

        }
    }
</script>

<style scoped lang="less">
    .distributionShare {
        padding: 28px;
        height: 100%;
    }

    .poster {
        .poster-seting {
            justify-content: inherit;
        }
        .poster-title {
            color: #545454;
            font-size: 14px;
            margin-left: 70px;
            padding: 10px 0px;
        }
        .poster-content {
            padding-bottom: 60px;
            height: 1000px;
            .poster-image {
                flex: 1;
                padding: 20px;
                /*max-width: 1100px;*/
                margin-right: 15px;
                border: 1px solid #ebedf1;
                height: 100%;
                overflow: auto;
                /*margin: auto;*/
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .poster-seting {
                width: 350px;
                padding: 42px 0px 0px 42px;
                border: 1px solid #ebedf1;
                height: 100%;
                .group {
                    justify-content: flex-start;
                    margin-bottom: 20px;
                    .label {
                        text-align: left;
                        color: #242424;
                        font-size: 14px;
                        margin-bottom: 5px;
                    }
                    .item {
                        min-width: 240px;
                        color: rgba(0, 0, 0, 0.65);
                        font-size: 12px;
                    }
                    .element {
                        display: flex;
                        flex-wrap: wrap;
                        .element-bth {
                            margin-right: 5px;
                            margin-bottom: 5px;
                        }
                    }

                    .color {
                        width: 360px;
                        display: flex;
                        .color_con {
                            width: 200px;
                            height: 32px;
                            border: 1px solid #ececec;
                        }
                        .change-color {
                            border: 1px solid #ececec;
                            margin-left: 15px;
                            width: 120px;
                            text-align: center;
                            height: 32px;
                            line-height: 32px;
                            color: #757575;
                            position: relative;
                            cursor: pointer;
                        }
                        .change-color::after {
                            content: '';
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            width: 8px;
                            height: 8px;
                            border-top: 1px solid #999;
                            border-right: 1px solid #999;
                            transform: rotate(135deg);
                        }
                    }
                }
            }
        }

    }

    .seting-title {
        margin-right: 20px;
        position: relative;
        color: #303030;
        font-size: 14px;
    }

    .seting-title::after {
        content: '';
        width: 4px;
        height: 20px;
        background: #1890ff;
        position: absolute;
        left: -8px;
        top: 50%;
        transform: translateY(-50%);
    }

    .upload {
        position: relative;
        width: auto !important;
        cursor: pointer;
        color: #ffffff !important;
        #upload {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            display: block;
            width: 100%;
            height: 100%;
        }
    }
</style>
