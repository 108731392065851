<template>
    <div class="activityShare">
        <div class="page-title flex">
            <div><span class="ant-btn-primary"></span>营销设置</div>
            <div>
                <div>
                    <!--<a-button type="primary" @click="_save">保存并发布</a-button>-->
                    <a-button style="background: #0ECB74;margin-left: 15px;color: #fff;border-color: #0ECB74" @click="_save">保存</a-button>
                    <a-button style="margin-left: 15px;" @click="_alertClose">返回</a-button>
                </div>
            </div>
        </div>

        <a-row type="flex" class="div-row">
            <a-col style="width: 100px;"><span class="required">*</span> 推广标题</a-col>
            <a-col style="width: 600px;">
                <div class="add-list" v-for="(item,index) in titleList" @mouseenter="_selectActive(index)" @mouseleave="selectActive = -1">
                    <div v-html="item"></div>
                    <div class="shade" v-if="selectActive==index"></div>
                    <div v-if="selectActive==index" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);width: 250px;text-align: center">
                        <a-button type="primary" style="margin-right: 10px;" @click="_setItem(index)">修改</a-button>
                        <a-popconfirm title="你确定要删除吗？" :getPopupContainer="triggerNode => {return triggerNode.parentNode || document.body;}" @confirm="_delItem(index)">
                            <a-icon slot="icon" type="question-circle-o" style="color: red" />
                            <a-button>删除</a-button>
                        </a-popconfirm>
                    </div>
                </div>
                <div id="websiteEditorElem" style="width:100%;height:250px"></div>
                <div style="border-radius: 4px;border: 1px solid #D9D9D9;text-align: center;padding: 5px 0;cursor: pointer" @click="_pushTitle('')" v-if="!pushTitle"><a-icon type="plus" /> 添加文案</div>
                <a-button type="primary" style="width: 100%" v-if="pushTitle" @click="_setTitle">确定</a-button>
            </a-col>
        </a-row>

        <div class="page-title flex" style="margin-top: 40px;">
            <div><span class="ant-btn-primary"></span>转发文案</div>
        </div>

        <a-row type="flex" align="middle" class="div-row">
            <a-col style="width: 100px;"><span class="required">*</span> 分享标题</a-col>
            <a-col style="width: 300px;">
                <a-input placeholder="请输入" v-model="Title"></a-input>
            </a-col>
        </a-row>

        <a-row type="flex" align="middle" class="div-row">
            <a-col style="width: 100px;"><span class="required">*</span> 分享描述</a-col>
            <a-col style="width: 300px;">
                <a-input placeholder="请输入" v-model="Describe"></a-input>
            </a-col>
        </a-row>

        <a-row type="flex" class="div-row">
            <a-col style="width: 100px;"><span class="required">*</span> 分享图标</a-col>
            <a-col style="width: 300px;">
                <div v-if="uploadImg" style="position: relative;width:100px;height:100px;display: inline-block;vertical-align: top;margin-right: 15px;">
                    <img :src="uploadImg[0].FileUrl" style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);max-width:100%;max-height: 100%;" alt="">
                </div>
                <div style="display: inline-block;vertical-align: top;position: relative">
                    <a-upload action="https://www.mocky.io/v2/5cc8019d300000980a055e76" list-type="picture-card" style="position: relative">
                        <div>
                            <a-icon type="plus" />
                            <div class="ant-upload-text">上传图片</div>
                        </div>
                    </a-upload>
                    <input type="file" @change="_uploadAttachment($event)" placeholder="" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: 1000;opacity: 0">
                </div>
            </a-col>
        </a-row>


    </div>
</template>

<script>
    import E from 'wangeditor';
    export default {
        name: "activityShare",
        props: {
            getData: {

            },
        },
        data(){
            return {
                phoneEditor: '',
                addActivity: '',
                titleList: [],
                pushTitle: true,
                setIndex: -1,

                Title: '',
                Describe: '',
                uploadImg: '',
                selectActive: -1,
            }
        },
        mounted() {
            let self = this;
            if(this.getData && !Array.isArray(this.getData)){
                this.titleList = this.getData.Content?JSON.parse(JSON.parse(this.getData.Content)):[];
                this.Title = this.getData.Title;
                this.Describe = this.getData.Describe;
                this.uploadImg = this.getData.Icon?[{FileUrl: this.getData.Icon}]:'';
            }
            setTimeout(function () {
                self.phoneEditor = new E('#websiteEditorElem');
                self.phoneEditor.customConfig.menus = [
                    'head',
                    'bold',
                    'italic',
                    'underline',
                    'foreColor',  // 文字颜色
                    'backColor',  // 背景颜色
                    'list',
                    'table',
                    'link',
                    'justify',
                    'image',
                    'emoticon'
                ];
                self.phoneEditor.customConfig.emotions = [
                    {
                        title: '默认',  // tab 的标题
                        type: 'emoji', // 'emoji' / 'image'
                        // emoji 表情，content 是一个数组即可
                        content: self.emoji.split(/\s/),
                    }
                ];
                self.phoneEditor.customConfig.onchange = function (html) {
                    self.addActivity = html;
                };
                // 创建一个富文本编辑器
                self.phoneEditor.create();
                self.phoneEditor.txt.html(self.addActivity);
            });
        },
        methods: {
            _delItem(i){
                this.titleList.splice(i,1);
            },
            _selectActive(i){
                this.selectActive = i;
            },
            _setTitle(){
                if(this.setIndex==-1){
                    if(this.addActivity == ''){
                        return false;
                    }else{
                        this.pushTitle = false;
                        this.titleList.push(this.addActivity);
                    }
                }else{
                    this.$set(this.titleList,this.setIndex,this.addActivity)
                }
                this.phoneEditor.txt.html('');
                this.addActivity = '';
                this.setIndex = -1;
                document.getElementById('websiteEditorElem').innerHTML = '';
                document.getElementById('websiteEditorElem').style.height = '0px';
            },
            _setItem(index){
                this.setIndex = index;
                this._pushTitle(this.titleList[index]);
            },
            _pushTitle(data){
                document.getElementById('websiteEditorElem').style.height = '250px';
                this.pushTitle = true;
                let self = this;
                self.phoneEditor = new E('#websiteEditorElem');
                self.phoneEditor.customConfig.menus = [
                    'head',
                    'bold',
                    'italic',
                    'underline',
                    'foreColor',  // 文字颜色
                    'backColor',  // 背景颜色
                    'list',
                    'table',
                    'link',
                    'justify',
                    'image',
                    'emoticon'
                ];
                self.phoneEditor.customConfig.emotions = [
                    {
                        title: '默认',  // tab 的标题
                        type: 'emoji', // 'emoji' / 'image'
                        // emoji 表情，content 是一个数组即可
                        content: self.emoji.split(/\s/),
                    }
                ];
                self.phoneEditor.customConfig.onchange = function (html) {
                    self.addActivity = html;
                };
                // 创建一个富文本编辑器
                self.phoneEditor.create();
                self.phoneEditor.txt.html(data);
            },
            _alertClose(){
                this.$emit('close',false);
            },
            _save(){
                this.$message.loading('保存中...');
                this.$axios.post(5836190,{
                    Pid: this.getData?this.getData.skipId:'',
                    Content: JSON.stringify(this.titleList),
                    Title: this.Title,
                    Describe: this.Describe,
                    Icon: this.uploadImg?this.uploadImg[0].FileUrl:'',
                },res=>{
                    this.$message.destroy();
                    if(res.data.code==0){
                        this.$message.success(res.data.msg);
                        this.addActivity = '';
                        this.Title = '';
                        this.Describe = '';
                        this.uploadImg = '';
                        this.phoneEditor.txt.html(self.addActivity);
                        this.$emit('setItem','')
                    }else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            _uploadAttachment(e){
                this.$message.loading('上传中...');
                this.__uploadFile__(e.target.files,'/qiniu/homework_token').then(files=>{
                    this.uploadImg = files;
                    this.$message.destroy();
                });
            },
        }
    }
</script>

<style scoped lang="less">
    .activityShare{
        padding: 20px;
        background: #fff;
        .page-title{
            span{
                display: inline-block;
                vertical-align: sub;
                margin-right: 8px;
                width: 3px;
                height: 21px;
            }
            color: #242424;
            font-size: 16px;
        }
        .div-row{
            margin-top: 20px;
            .row-label{
                width: 110px;
            }
            .required{
                display: inline-block;
                vertical-align: sub;
                color: #FF0000;
                font-size: 16px;
            }
            .textarea{
                width: 100%;
                height: 200px;
                resize: none;
            }
            .hint{
                margin: 5px 0;
                color: #ABB0BF;
                font-size: 13px;
            }
            .add-list{
                position: relative;
                padding: 15px;
                border: 1px solid #D9D9D9;
                border-radius: 4px;
                margin-bottom: 10px;
                cursor: pointer;
                .shade{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #000;
                    opacity: .5;
                }
            }
        }
    }
</style>
