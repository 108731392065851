<template>
    <div class="activityBaseMsg">
        <div class="page-title flex">
            <div><span class="ant-btn-primary"></span>推广类型</div>
            <div>
                <div>
                    <!--<a-button type="primary" @click="_save">保存并发布</a-button>-->
                    <a-button style="background: #0ECB74;margin-left: 15px;color: #fff;border-color: #0ECB74" @click="_save">保存</a-button>
                    <a-button style="margin-left: 15px;" @click="_alertClose">返回</a-button>
                </div>
            </div>
        </div>
        <a-row type="flex" align="middle" class="div-row">
            <a-col style="width: 100px;"><span class="required">*</span> 推广标题</a-col>
            <a-col style="width: 300px;"><a-input placeholder="请输入" v-model="Name"></a-input></a-col>
        </a-row>

        <a-row type="flex" align="middle" class="div-row">
            <a-col style="width: 100px;"><span class="required">*</span> 推广时间</a-col>
            <a-col style="width: 300px;"><a-range-picker v-model="PromoterTime" @change="onChangePromoterTime"/></a-col>
        </a-row>

        <a-row type="flex" align="middle" class="div-row">
            <a-col style="width: 100px;"><span class="required">*</span> 推广链接</a-col>
            <a-col style="width: 300px;"><a-input placeholder="请输入" v-model="LinkUrl"></a-input></a-col>
        </a-row>

        <a-row type="flex" align="middle" class="div-row">
            <a-col style="width: 100px;"><span class="required">*</span> 链接类型</a-col>
            <a-col style="width: 300px;">
                <a-select style="width: 100%" v-model="linkType">
                    <a-select-option :value="index" v-for="(item,index) in linkList">{{item}}</a-select-option>
                </a-select>
            </a-col>
        </a-row>

        <a-row type="flex" align="middle" class="div-row" v-if="linkType=='1'">
            <a-col style="width: 100px;"><span class="required">*</span> 小程序AppId</a-col>
            <a-col style="width: 300px;"><a-input placeholder="请输入" v-model="wxAppId"></a-input></a-col>
        </a-row>

        <a-row type="flex" align="middle" class="div-row">
            <a-col style="width: 100px;">成本价</a-col>
            <a-col style="width: 300px;"><a-input placeholder="请输入" v-model="CostPrice"></a-input></a-col>
        </a-row>

        <a-row type="flex" align="middle" class="div-row">
            <a-col style="width: 100px;">折后价</a-col>
            <a-col style="width: 300px;"><a-input placeholder="请输入" v-model="DiscountedPrice"></a-input></a-col>
        </a-row>

        <a-row type="flex" align="middle" class="div-row">
            <a-col style="width: 100px;"><span class="required">*</span> 推广企业</a-col>
            <a-col style="width: 300px;">
                <a-select style="width: 100%" v-model="selectPromoter">
                    <a-select-option value="">请选择</a-select-option>
                    <a-select-option :value="index" v-for="(item,index) in promoterList">{{item.name}}</a-select-option>
                </a-select>
            </a-col>
        </a-row>

        <a-row type="flex" align="middle" class="div-row">
            <a-col style="width: 100px;"><span class="required">*</span> 预估佣金</a-col>
            <a-col style="width: 300px;"><a-input placeholder="请输入" v-model="EstimatedCommission"></a-input></a-col>
        </a-row>

        <a-row type="flex" align="middle" class="div-row">
            <a-col style="width: 100px;">虚拟访问量</a-col>
            <a-col style="width: 300px;"><a-input placeholder="请输入" v-model="FictitiousVisit"></a-input></a-col>
        </a-row>

        <a-row type="flex" align="middle" class="div-row">
            <a-col style="width: 100px;">虚拟分享量</a-col>
            <a-col style="width: 300px;"><a-input placeholder="请输入" v-model="FictitiousPartake"></a-input></a-col>
        </a-row>

        <a-row type="flex" align="middle" class="div-row">
            <a-col style="width: 100px;">虚拟参与量</a-col>
            <a-col style="width: 300px;"><a-input placeholder="请输入" v-model="FictitiousParticipation"></a-input></a-col>
        </a-row>

        <a-row type="flex" class="div-row">
            <a-col style="width: 100px;">信息说明</a-col>
            <a-col style="width: 700px;">
                <div id="websiteEditorElem" style="width:100%;height:100%"></div>
            </a-col>
        </a-row>
    </div>
</template>

<script>
    import E from 'wangeditor';
    export default {
        name: "activityBaseMsg",
        props: {
            getData: {
                type: Object
            },
        },
        data() {
            return {
                promoterList: [
                    {
                        value: '4ae815d133588c71013358ad96450007',
                        name: '七田真'
                    }
                ],
                wxAppId: '',
                linkType: 0,
                linkList: ['H5','小程序'],
                phoneEditor: '',
                addActivity: '',
                Id:'',
                Name:'',
                StartTime:'',
                EndTime:'',
                LinkUrl:'',
                Content:'',
                Status:0,
                PromoterId:'',
                PromoterName:'',
                FictitiousVisit:'',
                FictitiousPartake:'',
                FictitiousParticipation:'',
                EstimatedCommission:'',
                CostPrice:'',
                DiscountedPrice:'',
                dateFormat:'YYYY-MM-DD',
                PromoterTime: [],
                selectPromoter: '',
            }
        },
        mounted() {
            let self = this;
            if(this.getData){
                self.Name = this.getData.Name;
                self.PromoterTime = [this.__moment__(this.getData.StartTime),this.__moment__(this.getData.EndTime)];
                self.StartTime = this.getData.StartTime;
                self.EndTime = this.getData.EndTime;
                self.LinkUrl = this.getData.LinkUrl;
                self.addActivity = this.getData.Content;
                self.Status = this.getData.Status;
                self.selectPromoter = this.getData.PromoterId?0:'';
                self.FictitiousVisit = this.getData.FictitiousVisit;
                self.FictitiousPartake = this.getData.FictitiousPartake;
                self.FictitiousParticipation = this.getData.FictitiousParticipation;
                self.EstimatedCommission = this.getData.EstimatedCommission;
                self.CostPrice = this.getData.CostPrice;
                self.DiscountedPrice = this.getData.DiscountedPrice;
                self.wxAppId = this.getData.AppId;
                self.linkType = this.getData.LinkUrlType - 1;
            }
            setTimeout(function () {
                self.phoneEditor = new E('#websiteEditorElem');
                self.phoneEditor.customConfig.menus = [
                    'head',
                    'bold',
                    'italic',
                    'underline',
                    'foreColor',  // 文字颜色
                    'backColor',  // 背景颜色
                    'list',
                    'table',
                    'link',
                    'justify',
                    'image',
                    'emoticon'
                ];
                self.phoneEditor.customConfig.emotions = [
                    {
                        title: '默认',  // tab 的标题
                        type: 'emoji', // 'emoji' / 'image'
                        // emoji 表情，content 是一个数组即可
                        content: self.emoji.split(/\s/),
                    }
                ];
                self.phoneEditor.customConfig.onchange = function (html) {
                    self.addActivity = html;
                };
                // 创建一个富文本编辑器
                self.phoneEditor.create();
                self.phoneEditor.txt.html(self.addActivity);
            });
        },

        methods: {
            _alertClose(){
                this.$emit('close',false);
            },
            _infoData(){
                let self = this;
                this.$message.loading('数据加载中...',0);
                this.$axios.get(8000034,{
                    Id: self.Id,
                },res=>{
                    if (res.data.code == 1) {
                        this.StatData = res.data.data;
                        this.tableDataCount = res.data.count
                    } else {
                        this.StatData = [];
                        this.tableDataCount = 0;
                    }
                    this.$message.destroy();
                })
            },
            _save(){
                let self = this;
                if(!this.Name || !self.StartTime || !self.EndTime || !self.LinkUrl || self.selectPromoter != '' || !self.EstimatedCommission){
                    this.$message.error('请填写完参数');
                    return false;
                }
                this.$message.loading('数据提交中...',0);
                this.$axios.post(8000034,{
                    Id: self.getData?self.getData.skipId:'',
                    Name: self.Name,
                    StartTime: self.StartTime,
                    EndTime: self.EndTime,
                    LinkUrl: self.LinkUrl,
                    LinkUrlType: self.linkType + 1,
                    AppId: self.wxAppId,
                    Content: self.addActivity,
                    Status: self.Status,
                    PromoterId: self.promoterList[self.selectPromoter].value,
                    PromoterName: self.promoterList[self.selectPromoter].name,
                    FictitiousVisit: self.FictitiousVisit,
                    FictitiousPartake: self.FictitiousPartake,
                    FictitiousParticipation: self.FictitiousParticipation,
                    EstimatedCommission: self.EstimatedCommission,
                    CostPrice: self.CostPrice,
                    DiscountedPrice: self.DiscountedPrice,
                },res=>{
                    this.$message.destroy();
                    if (res.data.code == 1) {
                        this.$message.success(res.data.msg);
                        this.$emit('setItem','')
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            onChangePromoterTime(d){
                this.StartTime = this.__moment__(d[0]).format(this.dateFormat);
                this.EndTime = this.__moment__(d[1]).format(this.dateFormat);
            },

        },

    }
</script>

<style scoped lang="less">
    .activityBaseMsg{
        padding: 20px;
        background: #fff;
        .page-title{
            span{
                display: inline-block;
                vertical-align: sub;
                margin-right: 8px;
                width: 3px;
                height: 21px;
            }
            color: #242424;
            font-size: 16px;
        }
        .div-row{
            margin-top: 20px;
            .required{
                display: inline-block;
                vertical-align: sub;
                color: #FF0000;
                font-size: 16px;
            }
        }
    }
</style>
